<template>
  <v-row>
    <v-col v-if="transaction.data.failureReason" cols="12">
      <h5 v-t="'page.account.transactions.detail.crypto.failure_reason'" class="primary400--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2" v-text="transaction.data.failureReason" />
    </v-col>
    <v-col cols="12" md="4">
      <h5 class="grey100--text text-body-2">
        <span class="text-capitalize mr-1" v-text="currency" />
        <span v-t="'common.amount'" />
      </h5>
      <p class="mb-0 text--secondary lh-1-4 text-body-2" v-text="cryptoAmount" />
    </v-col>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.deposit_value'" class="grey100--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2">
        {{ transaction.data.depositValue | toScrap }} scrap
      </p>
    </v-col>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.bonus'" class="grey100--text text-body-2" />
      <p class="mb-0 lh-1-4 text-body-2">
        <span v-if="bonus.value" class="green400--text">
          +{{ bonus.value | toScrap }} ({{ bonus.amount * 100 | toLocale }}%)
        </span>
        <span v-else class="text--disabled">&mdash;</span>
      </p>
    </v-col>
    <v-col cols="12">
      <h5 class="grey100--text text-body-2">
        <span class="text-capitalize mr-1" v-text="currency" />
        <span v-t="'page.account.transactions.detail.crypto.price_at_deposit'" />
      </h5>
      <p class="mb-0 lh-1-4 text-body-2">
        <span class="text--secondary">
          ${{ cryptoPrice.price / 100 | toCurrency }}
        </span>

        <span class="text--disabled text-caption">
          &ndash;
          <span v-t="'page.account.transactions.detail.crypto.updated_at'" />
          {{ cryptoPrice.lastUpdatedAt | toDateFormat('YYYY-MM-DD HH:mm:ss') }}
        </span>
      </p>
    </v-col>
    <v-col cols="12">
      <h5 class="grey100--text text-body-2">
        <span class="text-capitalize mr-1" v-text="currency" />
        <span v-t="'page.account.transactions.detail.crypto.transaction'" />
      </h5>
      <a
        v-if="cryptoExplorerLink"
        :href="cryptoExplorerLink"
        target="_blank" rel="noopener noreferrer"
        class="link blue400--text lh-1-4 text-caption"
      >
        <fai :icon="['fad', 'external-link-alt']" class="mr-2" />
        <span v-text="transaction.data.hash" />
      </a>
      <p v-else class="mb-0 text--secondary lh-1-4 text-caption" v-text="transaction.data.hash" />
    </v-col>
  </v-row>
</template>

<script>
import BigNumber from 'bignumber.js'
import { CRYPTO_CODES, CRYPTO_POW } from '@/utils/constants'

const CRYPTO_EXPLORERS = {
  ethereum: 'https://etherscan.io/tx/',
  usdt: 'https://etherscan.io/tx/',
  bitcoin: 'https://www.blockchain.com/btc/tx/',
  litecoin: 'https://blockchair.com/litecoin/transaction/',
}

export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currency() {
      return this.transaction.data?.currency
    },
    currencyDecimals() {
      return CRYPTO_POW[this.currency]
    },
    currencyCode() {
      return CRYPTO_CODES[this.currency]
    },
    cryptoAmount() {
      const { amount } = this.transaction.data
      const { currencyDecimals } = this

      return new BigNumber(amount).shiftedBy(-currencyDecimals).toFixed(7)
    },
    cryptoPrice() {
      return this.transaction.data.cryptoPrice
    },
    bonus() {
      return {
        amount: this.transaction.data?.bonus ?? 0,
        value: this.transaction.data?.bonusValue ?? 0,
      }
    },
    cryptoExplorerLink() {
      const explorer = CRYPTO_EXPLORERS[this.currency]
      if (!explorer) return null

      return `${explorer}${this.transaction.data.hash}`
    },
  },
}
</script>
